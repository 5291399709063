<template>
  <main>
    <ConditionsBanner />
    <ConditionsList />
  </main>
</template>

<script>
import ConditionsBanner from "@/components/policy/ConditionsBanner";
import ConditionsList from "@/components/policy/ConditionsList";
export default {
  name: "Policy",
  components: {
    ConditionsBanner,
    ConditionsList
  }
};
</script>
<style lang="scss">
@import "@/assets/scss/views/_policy";
</style>
