<template>
  <section class="conditions-list-policy">
    <div class="container">
      <div class="row">
        <div class="col-xx-lg-8 col-x-lg-8 col-lg-8 col-md-7 col-xs-12">
          <div class="conditions-list-policy__content">

            <div class="main-title">
              ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ
            </div>
            <div class="sub-title">Остання зміна – серпень 2019 року</div>
            <div class="center-text">(Резюме змін: зміна юридичної адреси ТОВ «АстраЗенека Україна)</div>

            <div class="conditions-list-policy__item" id="conditions-0">
              <!--              <h3 class="conditions-list-policy__title">-->
              <!--                <span class="conditions-list-policy__title-number">I.</span>-->
              <!--                <span class="conditions-list-policy__title-text"-->
              <!--                >Загальні положення</span-->
              <!--                >-->
              <!--              </h3>-->
              <div class="conditions-list-policy__item-content">
                <div class="conditions-list-policy__item-group">
                  <div class="conditions-list-policy__text">
                    <ol>
                      <li>
                        Цей сайт призначений для інформування зацікавлених осіб про Програму підтримки пацієнтів
                        «ТерапіяПЛЮС» (далі – <strong>“Програма”</strong>), її умови, а також для реєстрації у Програмі
                        осіб, які
                        бажають стати учасниками Програми. Детальніше про Правила та Умови Програми Ви можете
                        дізнатися за
                        <router-link to="/terms-and-conditions">посиланням</router-link>.
                      </li>
                      <li>
                        Організатором Програми та власником цього сайту є міжнародна фармацевтична компанія
                        AstraZeneca (АстраЗенека), що на території України представлена ТОВ «АстраЗенека Україна»,
                        код ЄДРПОУ 37037434, <i>зареєстрована за адресою: 01033, м. Київ, вул. Сім’ї Прахових, буд.
                        54</i>
                        (далі – <strong>«Організатор Програми»</strong>).
                      </li>
                      <li>
                        Адміністратором Програми є ТОВ «Медікард», юридична особа за законодавством України,
                        код ЄДРПОУ 38199205, <i>зареєстрована за адресою: 49100, Дніпропетровська обл., м. Дніпро,
                        проспект Героїв, буд. 11Л, каб. 13.</i> (далі – <strong>«Адміністратор Програми»</strong>).
                        Адміністратор
                        Програми виконує технічну та інформаційну підтримку Програми (зокрема, але не виключно,
                        реєстрацію учасників Програми), а також здійснює збір, обробку та зберігання персональних
                        даних, які збираються в рамках Програми. <strong>Адміністратор Програми є Володільцем
                        персональних даних в рамках Програми</strong> і займається усіма питаннями, пов’язаними із
                        такими
                        персональними даними.
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div class="conditions-list-policy__item" id="conditions-1">
              <h3 class="conditions-list-policy__title">
                <span class="conditions-list-policy__title-text">Про що ця Політика конфіденційності?</span>
              </h3>
              <div class="conditions-list-policy__item-content">
                <div class="conditions-list-policy__item-group">
                  <div class="conditions-list-policy__text">
                    <ol>
                      <li>
                        В цій Політиці конфіденційності (далі – <strong>“Політика”</strong>) описується, яка особиста
                        інформація
                        користувачів веб-сайту та/або учасників Програми може збиратись, яким чином така
                        інформація може використовуватись та основі принципи використання такої інформації.
                      </li>
                      <li>
                        Ця Політика може змінюватись, тому будь ласка, перевіряйте її час від часу для того, щоб бути
                        в курсі актуальних умов. Вам буде повідомлено про будь-які зміни в умовах, якщо обов’язок
                        повідомляти Вам про такі зміни встановлений законодавством.
                      </li>
                      <li>
                        Ця Політика і всі питання, пов’язані із нею, регулюються правом України. Будь-які спори, які
                        виникають у зв’язку із цією Політикою, вирішуються шляхом переговорів. Якщо переговори не
                        були успішними, будь-які спори, які виникають у зв’язку із цією Політикою, вирішуються в
                        судах України відповідно до застосовного процесуального законодавства України.
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div class="conditions-list-policy__item" id="conditions-2">
              <h3 class="conditions-list-policy__title">
                <!--                <span class="conditions-list-policy__title-number">II.</span>-->
                <span class="conditions-list-policy__title-text">
                  Що таке «персональні дані» та «обробка персональних даних»?
                </span>
              </h3>
              <div class="conditions-list-policy__item-content">
                <div class="conditions-list-policy__text">
                  <ol>
                    <li>
                      Персональні дані – будь-яка інформація про Вас чи про осіб, яких Ви законно представляєте
                      (дітей, підопічних), яка дозволяє ідентифікувати Вас чи таких осіб.
                    </li>
                    <li>
                      Обробка персональних даних – будь-які дії з персональними даними (в т. ч. їх збирання,
                      використання, передача, зокрема і закордон, видалення, знеособлення).
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div class="conditions-list-policy__item" id="conditions-3">
              <h3 class="conditions-list-policy__title">
                <!--                <span class="conditions-list-policy__title-number">III.</span>-->
                <span class="conditions-list-policy__title-text"
                >Які персональні дані обробляються через веб-сайт Програми?</span>
              </h3>
              <div class="conditions-list-policy__item-content">
                <div class="conditions-list-policy__text">
                  <ol>
                    <li>
                      Адміністратор Програми збирає та обробляє персональні дані учасників Програми та осіб, які
                      звертаються до Адміністратора із запитами щодо Програми, – тобто ті дані, які Ви добровільно
                      надаєте про себе або про особу, яку Ви законно представляєте, у зв’язку із реєстрацією у
                      Програмі або у процесі участі у Програмі, або ж звертаючись до Адміністратора із питаннями
                      щодо Програми.
                    </li>
                    <li>
                      До таких персональних даних відносяться:
                      <ul class="dot">
                        <li>Прізвище, ім’я, по-батькові, номер мобільного телефону, електронна адреса та будь-які інші
                          дані, які Ви добровільно надаєте.
                        </li>
                        <li>Також, оскільки Програма поширюється лише на вичерпний перелік лікарських
                          засобів, то у разі Вашої реєстрації у Програмі та у процесі Вашого користування
                          Програмою Адміністратор Програми отримуватиме опосередковану інформацію про
                          стан здоров’я Вас або особи, яку Ви законно представляєте. Така опосередкована
                          інформація про стан здоров’я обмежується даними про покупки лікарських засобів за
                          карткою учасника Програми. У будь-якому випадку Адміністратор Програми не матиме
                          доступу до інших конфіденційних даних стосовно стану здоров’я (наприклад, діагнозу,
                          перебігу захворювання тощо), крім випадків, коли Ви самостійно та добровільно
                          повідомляєте про такі дані (наприклад, в рамках окремого Вашого запиту).
                        </li>
                      </ul>
                    </li>
                    <li><strong>Реєструючись у Програмі або звертаючись до Адміністратора Програми з окремим запитом,
                      Ви надаєте Адміністратору Програми свою однозначну згоду на обробку персональних
                      даних, які Ви добровільно надаєте під час реєстрації чи у своєму зверненні, на умовах,
                      прописаних у цій Політиці. </strong></li>
                    <li>Про цілі збору та обробки даних, осіб, яким такі дані можуть передаватися, свої права щодо
                      наданих Вами персональних даних Ви можете дізнатись нижче у цій Політиці.
                    </li>
                    <li>Крім того, через цей веб-сайт Організатор Програми та Адміністратор Програми можуть
                      отримувати доступ до файлів куки (cookies) або подібних файлів користувачів веб-сайту. Про
                      те, що таке файли куки (cookies), та як вони можуть використовуватись, Ви можете дізнатись
                      нижче у цій Політиці.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div class="conditions-list-policy__item" id="conditions-4">
              <h3 class="conditions-list-policy__title">
                <!--                <span class="conditions-list-policy__title-number">IV.</span>-->
                <span class="conditions-list-policy__title-text">Для чого Адміністратор Програми збирає та обробляє інформацію та персональні дані?</span>
              </h3>
              <div class="conditions-list-policy__item-content">
                <div class="conditions-list-policy__text">
                  <ol>
                    <li>
                      Інформація та персональні дані про Вас чи осіб, яких Ви законно представляєте, збираються та
                      обробляються Адміністратором Програми для повноцінної та ефективної реалізації Програми,
                      в тому числі для:

                      <ul>
                        <li>
                          — реєстрації Вас чи осіб, яких Ви законно представляєте, в якості учасника Програми;
                        </li>
                        <li>
                          — надання можливості учасникам Програми користування перевагами Програми, в тому
                          числі для надання можливості отримання учасниками Програми знижок на лікарські
                          засоби, що включені до Програми;
                        </li>
                        <li>
                          — надання учасникам Програми інформації стосовно Програми або змін у ній шляхом
                          комунікацій за контактними деталями, наданими учасниками Програми;
                        </li>
                        <li>
                          — здійснення контролю за правомірним використанням карток учасників Програми –
                          зокрема, для мінімізації ризиків використання картки учасника Програми із
                          зловживаннями чи з шахрайськими цілями чи з цілями введення в оману чи з будь-якими
                          іншими сумнівними або неправомірними цілями;
                        </li>
                        <li>
                          — надання відповіді на Ваші звернення.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Інформація, яку Ви надаєте, не буде використовуватись в інших цілях без отримання від Вас
                      окремої згоди на це.

                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div class="conditions-list-policy__item" id="conditions-5">
              <h3 class="conditions-list-policy__title">
                <!--                <span class="conditions-list-policy__title-number">V.</span>-->
                <span class="conditions-list-policy__title-text">Хто може мати доступ/ кому можуть передаватися персональні дані, надані Вами?</span>
              </h3>
              <div class="conditions-list-policy__item-content">
                <div class="conditions-list-policy__text">
                  <ol>
                    <li>Володільцем персональних даних, що надаються фізичними особами у зв’язку із Програмою,
                      є Адміністратор Програми.
                    </li>
                    <li>Разом з тим, в деяких випадках для реалізації Програми у процеси, пов’язані з реалізацією
                      Програми, можуть бути залучені інші компанії (наприклад, але не виключно, IT-провайдери,
                      провайдери послуг із хостингу веб-сайту тощо). Зазначені особи можуть отримати доступ до
                      персональних даних, наданих Вами, виключно в тому випадку, якщо це необхідно для
                      виконання їх функцій в межах Програми, та у тій мірі, в якій це потрібно для виконання таких
                      функцій. Ні в якому разі таким особам не буде надане право використовувати персональні
                      дані, надані Вами, в будь-яких інших цілях, аніж виконання їх функцій в межах Програми.
                    </li>
                    <li>Крім того, чинне законодавство зобов’язує Адміністратора Програми надавати інформацію
                      про Вас чи осіб, яких Ви законно представляєте, у відповідь на законні і належним чином
                      обґрунтовані запити судових, правоохоронних та інших державних органів.
                    </li>
                    <li>Ви погоджуєтеся, що Вас не треба повідомляти про кожну окрему передачу наданих Вами
                      персональних даних чи надання доступу до таких даних зазначеним вище особам.
                    </li>
                    <li>Персональні дані також можуть передаватися новому адміністратору Програми у випадку
                      зміни адміністратора з будь-яких причин (див. нижче розділ
                      <span @click="openAcardion('#conditions-7')">«Зміна
                        Володільця
                        персональних даних»</span>).
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div id="conditions-6" class="conditions-list-policy__item">
              <h3 class="conditions-list-policy__title">
                <!--                <span class="conditions-list-policy__title-number">VI.</span>-->
                <span class="conditions-list-policy__title-text">Передача персональних даних за кордон</span>
              </h3>
              <div class="conditions-list-policy__item-content">
                <div class="conditions-list-policy__text">
                  <ol>
                    <li>
                      У процесі та для цілей реалізації Програми надані Вами персональні дані можуть передаватися
                      за кордон, в тому числі у країни або юрисдикції, які не забезпечують такий же рівень захисту
                      персональних даних, як законодавство України. Наприклад, такі ситуації можуть мати місце,
                      коли для цілей реалізації Програми залучаються іноземні підрядники.<br>
                      Проте, незалежно від юрисдикції, куди можуть передаватися персональні дані в рамках
                      Програми, будуть застосовані всі розумні та можливі заходи для захисту таких даних та для
                      забезпечення їх належної конфіденційності відповідно до положень цієї Політики. Це
                      реалізується, зокрема, але не виключно, через ретельний вибір та перевірку потенційних
                      контрагентів, включення відповідних положень у договори з такими контрагентами.
                    </li>
                  </ol>
                </div>
              </div>
            </div>

            <div id="conditions-7" class="conditions-list-policy__item">
              <h3 class="conditions-list-policy__title">
                <!--                <span class="conditions-list-policy__title-number">VI.</span>-->
                <span class="conditions-list-policy__title-text">Зміна Володільця персональних даних</span>
              </h3>
              <div class="conditions-list-policy__item-content">
                <div class="conditions-list-policy__text">
                  <ol>
                    <li>
                      Якщо у майбутньому з будь-яких причин відбудеться зміна Володільця персональних даних
                      (Адміністратора Програми), то Вам буде повідомлено про це будь-яким прийнятним
                      способом: розміщенням відповідного оголошення на веб-сайті Програми або у будь-яких
                      публічних джерелах та/або шляхом окремих прямих комунікацій з Вами. При цьому,
                      персональні дані, надані Вами, і надалі будуть оброблятися у відповідності до цієї Політики.
                    </li>
                    <li>Ви маєте право в будь-який час відкликати свою згоду на обробку персональних даних (див.
                      більше у відповідь на питання
                      <span @click="openAcardion('#conditions-10')">«Чи можете Ви відкликати
                        Вашу згоду на
                        обробку персональних
                        даних?»</span>).
                    </li>
                  </ol>
                </div>
              </div>
            </div>

            <div id="conditions-8" class="conditions-list-policy__item">
              <h3 class="conditions-list-policy__title">
                <!--                <span class="conditions-list-policy__title-number">VI.</span>-->
                <span
                    class="conditions-list-policy__title-text">Як довго зберігаються надані Вами персональні дані?</span>
              </h3>
              <div class="conditions-list-policy__item-content">
                <div class="conditions-list-policy__text">
                  <ol>
                    <li>
                      Персональні дані, надані Вами, зберігаються протягом строку дії Програми.
                    </li>
                    <li>Ви погоджуєтесь з тим, що Адміністратор Програми не буде повідомляти Вас про будь-які
                      зміни, видалення чи знищення таких персональних даних або обмеження доступу до них.
                    </li>
                  </ol>
                </div>
              </div>
            </div>

            <div id="conditions-9" class="conditions-list-policy__item">
              <h3 class="conditions-list-policy__title">
                <!--                <span class="conditions-list-policy__title-number">VI.</span>-->
                <span class="conditions-list-policy__title-text">Які у Вас є права щодо наданих Вами персональних даних?
</span>
              </h3>
              <div class="conditions-list-policy__item-content">
                <div class="conditions-list-policy__text">
                  <ol>
                    <li>
                      В будь-який час Ви можете:
                      <ul>
                        <li>— зв’язатися із Адміністратором Програми з будь-яких питань, які стосуються обробки
                          персональних даних в рамках Програми або доступу до них,
                        </li>
                        <li>— відкликати свою згоду на обробку персональних даних (див. більше у відповідь на питання
                          <span @click="openAcardion('#conditions-10')">«Чи можете Ви
                            відкликати Вашу згоду на обробку персональних
                            даних?»</span>);
                        </li>
                        <li>— звертатися за захистом своїх законних прав до органів державної влади;</li>
                        <li>— користуватись іншими правами згідно <a
                            href="https://zakon.rada.gov.ua/laws/show/2297-17/paran65#n65" target="_blank">ст.8 Закону
                          України «Про захист персональних
                          даних»</a>.
                        </li>
                      </ul>
                    </li>
                    <li>Реєструючись у Програмі або надаючи дані в рамках окремого Вашого запиту, Ви
                      підтверджуєте, що Ви ознайомилися і проінформовані про такі права.
                    </li>
                  </ol>
                </div>
              </div>
            </div>

            <div id="conditions-10" class="conditions-list-policy__item">
              <h3 class="conditions-list-policy__title">
                <!--                <span class="conditions-list-policy__title-number">VI.</span>-->
                <span class="conditions-list-policy__title-text">Чи можете Ви відкликати Вашу згоду на обробку персональних даних?</span>
              </h3>
              <div class="conditions-list-policy__item-content">
                <div class="conditions-list-policy__text">
                  <ol>
                    <li>
                      Якщо Ви не бажаєте, щоб Ваші дані оброблялись, Ви можете в будь-який час відкликати свою
                      згоду на обробку наданих Вами персональних даних. Для того щоб відкликати свою згоду на
                      обробку, будь-ласка, зверніться до Адміністратора Програми за контактами за
                      <router-link
                          to="/contacts">посиланням</router-link>.

                    </li>
                    <li>Звертаємо Вашу увагу, що відкликання згоди на обробку персональних даних унеможливлює
                      подальшу участь у Програмі та використання картки учасника Програми/користування
                      перевагами Програми.
                    </li>
                    <li>Таким чином, після отримання Адміністратором Програми звернення про відкликання згоди
                      на обробку даних/ вимоги про припинення обробки даних: (i) аккаунт та картку Учасника
                      Програми буде деактивовано (анульовано), картка учасника Програми буде недоступною для
                      користування; (ii) Адміністратор Програми припинить подальшу обробку Ваших даних; (ііі) для
                      цілей документування і прозорого відображення операцій в Програмі Адміністратор буде
                      зберігати Ваше повідомлення про відкликання та історію операцій за вказаним Вами
                      аккаунтом протягом строку дії Програми; (іv) решта даних буде видалена в тій мірі, в якій це
                      технічно можливо.
                    </li>
                  </ol>
                </div>
              </div>
            </div>

            <div id="conditions-11" class="conditions-list-policy__item">
              <h3 class="conditions-list-policy__title">
                <!--                <span class="conditions-list-policy__title-number">VI.</span>-->
                <span class="conditions-list-policy__title-text">Обробка даних осіб, що не досягли 18 років.</span>
              </h3>
              <div class="conditions-list-policy__item-content">
                <div class="conditions-list-policy__text">
                  <ol>
                    <li>
                      Цей веб-сайт призначений для дієздатних користувачів, які досягли 18 років.
                    </li>
                    <li>Учасником Програми може стати кожна фізична особа, якій лікарем було призначено
                      лікарський засіб, міжнародна непатентована назва якого включена у Програму, за умови
                      досягнення фізичною особою 18 років та повної дієздатності такої особи. Для фізичних осіб,
                      які не досягли 18 років або не мають повної дієздатності, участь у Програмі можлива лише
                      через їх законних представників.
                    </li>
                    <li>Якщо Ви – один із батьків/опікун/піклувальник, і Ваша дитина/підопічний зареєструвалися в
                      Програмі без Вашої згоди, Ви можете зв'язатися з Адміністратором Програми для вирішення
                      цього питання за контактами за
                      <router-link
                          to="/contacts">посиланням</router-link>.
                    </li>
                  </ol>
                </div>
              </div>
            </div>

            <div id="conditions-12" class="conditions-list-policy__item">
              <h3 class="conditions-list-policy__title">
                <!--                <span class="conditions-list-policy__title-number">VI.</span>-->
                <span class="conditions-list-policy__title-text">Як забезпечується конфіденційність та захист наданих Вами персональних даних?</span>
              </h3>
              <div class="conditions-list-policy__item-content">
                <div class="conditions-list-policy__text">
                  <ol>
                    <li>
                      Надані Вами персональні дані обробляються у суворій відповідності із чинним
                      законодавством із застосуванням усіх необхідних комерційно виправданих технічних та
                      організаційних заходів для їх захисту від випадкових втрати або знищення, від незаконної
                      обробки, у тому числі незаконного знищення чи доступу.
                    </li>
                  </ol>
                </div>
              </div>
            </div>

            <div id="conditions-13" class="conditions-list-policy__item">
              <h3 class="conditions-list-policy__title">
                <!--                <span class="conditions-list-policy__title-number">VI.</span>-->
                <span class="conditions-list-policy__title-text">Чи можете Ви відмовитися від інформаційної розсилки щодо Програми?
</span>
              </h3>
              <div class="conditions-list-policy__item-content">
                <div class="conditions-list-policy__text">
                  <ol>
                    <li>
                      Реєструючись у Програмі, Ви надаєте згоду на отримання комунікацій щодо Програми за
                      наданими Вами контактними даними. Ви маєте право в будь-який час відмовитися від
                      отримання таких комунікацій, сповістивши про це Адміністратора Програми.
                    </li>
                    <li>Детальніше про опції сповіщення Адміністратора Програми про бажання відписатися від
                      отримання комунікацій Ви можете дізнатися на окремій сторінці за
                      <router-link
                          to="/contacts">посиланням</router-link>.
                    </li>
                    <li>Зверніть увагу, що відмова від інформаційної розсилки не означає відкликання Вами згоди на
                      обробку персональних даних, наданих Вами, повністю. Адміністратор Програми
                      продовжуватиме обробку таких персональних даних для інших цілей Програми, визначених
                      вище в цій Політиці.
                    </li>
                  </ol>
                </div>
              </div>
            </div>

            <div id="conditions-14" class="conditions-list-policy__item">
              <h3 class="conditions-list-policy__title">
                <!--                <span class="conditions-list-policy__title-number">VI.</span>-->
                <span
                    class="conditions-list-policy__title-text">Контакти для звернень з питань персональних даних</span>
              </h3>
              <div class="conditions-list-policy__item-content">
                <div class="conditions-list-policy__text">
                  <ol>
                    <li>
                      З будь-якими питаннями, коментарями та зверненнями щодо персональних даних в рамках
                      Програми, будь-ласка, звертайтеся до Адміністратора Програми за контактами за
                      <router-link
                          to="/contacts">посиланням</router-link>.
                    </li>
                  </ol>
                </div>
              </div>
            </div>

            <div id="conditions-15" class="conditions-list-policy__item">
              <h3 class="conditions-list-policy__title">
                <!--                <span class="conditions-list-policy__title-number">VI.</span>-->
                <span class="conditions-list-policy__title-text">Використання cookies
</span>
              </h3>
              <div class="conditions-list-policy__item-content">
                <div class="conditions-list-policy__text">
                  <ol>
                    <li>
                      Цей веб-сайт може використовувати cookies та подібні файли (далі – cookies) для отримання
                      інформації про те, як Ви використовуєте веб-сайт Програми. Це дозволяє вдосконалювати
                      наповнення та функціональність веб-сайту.
                    </li>
                    <li>Більшість браузерів дозволяють Вам керувати використанням cookies на Вашому пристрої (в
                      тому числі відключати їх використання). Якщо Ви не хочете, щоб сайт Програми
                      використовував cookies на Вашому пристрої, Ви можете встановити відповідні налаштування
                      у Вашому браузері. Зверніть увагу, що після відключення cookies для сайту Програми він може
                      некоректно відображатися на Вашому пристрої.
                    </li>
                  </ol>
                </div>
              </div>
            </div>

            <div id="conditions-16" class="conditions-list-policy__item">
              <h3 class="conditions-list-policy__title">
                <!--                <span class="conditions-list-policy__title-number">VI.</span>-->
                <span class="conditions-list-policy__title-text">Посилання на інші веб-сайти</span>
              </h3>
              <div class="conditions-list-policy__item-content">
                <div class="conditions-list-policy__text">
                  <ol>
                    <li>
                      На цьому веб-сайті можуть бути наявні посилання на інші сайти, які в своїй діяльності не
                      застосовують цю Політику конфіденційності. Коли Ви переходите за посиланням на інші веб-сайти, дія
                      цієї Політики на такі веб-сайти поширюватись не буде. Будь ласка, переглядайте
                      політику у сфері конфіденційності та персональних даних кожного сайту перед тим, як
                      надавати будь-які персональні дані, за якими можуть ідентифікувати Вашу особу.
                    </li>

                  </ol>
                </div>
              </div>
            </div>

            <div id="conditions-17" class="conditions-list-policy__item">
              <h3 class="conditions-list-policy__title">
                <!--                <span class="conditions-list-policy__title-number">VI.</span>-->
                <span class="conditions-list-policy__title-text">Обмеження відповідальності</span>
              </h3>
              <div class="conditions-list-policy__item-content">
                <div class="conditions-list-policy__text">
                  <ol>
                    <li>
                      Організатор Програми та/або Адміністратор Програми не несуть відповідальності за тимчасові
                      збої і перерви в роботі веб-сайту, недостатню якість або швидкість надання послуг по
                      відтворенню та демонстрації матеріалів, які можуть бути наслідком дій провайдера або інших
                      незалежних від нас обставин. Організатор Програми та/або Адміністратор Програми не несуть
                      відповідальності за дії зловмисників, які можуть призвести до несанкціонованого доступу до
                      інформації про Вас, якщо такий доступ стався незважаючи на всі докладені належні зусилля
                      для захисту інформації. У випадку технічних збоїв ми будемо вживати всіх можливих і від нас
                      залежних заходів для оперативного відновлення працездатності сайту.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
            class="col-xx-lg-4 col-x-lg-4 col-lg-4 col-md-5 col-xs-12"
            v-if="!getResponsive">
          <div class="conditions-list-policy__sidebar">
            <ol>
              <li>
                <!--                <span>I.</span>-->
                <a href="#conditions-1">Про що ця Політика конфіденційності?</a>
              </li>
              <li>
                <!--                <span>II.</span>-->
                <a href="#conditions-2">
                  Що таке «персональні дані» та «обробка персональних даних»?
                </a>
              </li>
              <li>
                <!--                <span>III.</span>-->
                <a href="#conditions-3">Які персональні дані обробляються через веб-сайт Програми?</a>
              </li>
              <li>
                <!--                <span>IV.</span>-->
                <a href="#conditions-4">Для чого Адміністратор Програми збирає та обробляє інформацію та персональні
                  дані?</a>
              </li>
              <li>
                <!--                <span>V.</span>-->
                <a href="#conditions-5">Хто може мати доступ/ кому можуть передаватися персональні дані, надані
                  Вами?</a>
              </li>
              <li>
                <!--                <span>VI.</span>-->
                <a href="#conditions-6">Передача персональних даних за кордон</a>
              </li>
              <li><a href="#conditions-7">Зміна Володільця персональних даних</a></li>
              <li><a href="#conditions-8">Як довго зберігаються надані Вами персональні дані?</a></li>
              <li><a href="#conditions-9">Які у Вас є права щодо наданих Вами персональних даних?</a></li>
              <li><a href="#conditions-10">Чи можете Ви відкликати Вашу згоду на обробку персональних даних?</a></li>
              <li><a href="#conditions-11">Обробка даних осіб, що не досягли 18 років.</a></li>
              <li><a href="#conditions-12">Як забезпечується конфіденційність та захист наданих Вами персональних
                даних?</a></li>
              <li><a href="#conditions-13">Чи можете Ви відмовитися від інформаційної розсилки щодо Програми?</a></li>
              <li><a href="#conditions-14">Контакти для звернень з питань персональних даних</a></li>
              <li><a href="#conditions-15">Використання cookies</a></li>
              <li><a href="#conditions-16">Посилання на інші веб-сайти</a></li>
              <li><a href="#conditions-17">Обмеження відповідальності</a></li>
            </ol>


          </div>
          <div @click="up()" class="conditions-list-policy__up-btn">
            <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path class="bg" opacity="0.2"
                    d="M46 48C47.1046 48 48 47.1046 48 46L48 2C48 0.895431 47.1046 -1.83217e-07 46 -1.96389e-07L2 -7.21084e-07C0.895432 -7.34256e-07 5.61716e-07 0.89543 5.48545e-07 2L2.38498e-08 46C1.06779e-08 47.1046 0.895431 48 2 48L46 48Z"
                    fill="#70C2D5"/>
              <path class="arrow" fill-rule="evenodd" clip-rule="evenodd"
                    d="M32.5157 27.7675L33.7822 26.501L24.2831 17.0019L24.283 17.002L24.2829 17.002L14.7838 26.5011L16.0504 27.7677L24.2831 19.535L32.5157 27.7675Z"
                    fill="#69B6C7"/>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ConditionsList",
  computed: {
    ...mapGetters(["getResponsive"])
  },
  mounted() {
    setTimeout(() => {

      // If not responsive width
      if (!this.getResponsive) {
        let mainNavLinks = document.querySelectorAll(
            ".conditions-list-policy__sidebar ol li a"
        );
        mainNavLinks.forEach(link => {
          link.addEventListener("click", event => {
            event.preventDefault();
            let target = document.querySelector(event.target.hash);
            target.scrollIntoView();
          });
        });
        window.addEventListener("scroll", () => {
          let fromTop = window.scrollY;

          mainNavLinks.forEach(link => {
            let section = document.querySelector(link.hash);
            if (section) {
              if (
                  section.offsetTop <= fromTop &&
                  section.offsetTop + section.offsetHeight > fromTop
              ) {
                link.parentElement.classList.add("active");
              } else {
                link.parentElement.classList.remove("active");
              }
            }
          });
        });
      }
      // If responsive width
      else {
        // listeners for faqs
        let accordions = document.querySelectorAll(".conditions-list-policy__title");
        accordions.forEach(accordion => {
          accordion.addEventListener("click", () => {
            if (!accordion.parentNode.classList.contains("active")) {
              accordions.forEach(accordion => {
                accordion.parentElement.classList.remove("active");
                accordion.parentElement.children[1].setAttribute(
                    "style",
                    "height:0px"
                );
              });
            }
            if (accordion.parentNode.classList.contains("active")) {
              accordion.parentElement.classList.remove("active");
              accordion.parentElement.children[1].setAttribute(
                  "style",
                  "height:0px"
              );
            } else {
              accordion.parentElement.classList.add("active");
              var contentHeight =
                  accordion.parentElement.children[1].children[0].clientHeight;
              accordion.parentElement.children[1].setAttribute(
                  "style",
                  "height:" + contentHeight + "px"
              );
              setTimeout(() => {
                accordion.scrollIntoView()
              }, 20)
            }
          });
        });
      }
    }, 200)
  },
  methods: {
    openAcardion(id) {
      if (this.getResponsive) {

        let accordions = document.querySelectorAll(".conditions-list-policy__title");

        accordions.forEach(accordion => {
          accordion.parentElement.classList.remove("active");
          accordion.parentElement.children[1].setAttribute(
              "style",
              "height:0px"
          );
        });

        let accordion = document.querySelector(id)

        accordion.classList.add("active");
        let contentHeight =
            accordion.children[1].children[0].clientHeight;
        accordion.children[1].setAttribute(
            "style",
            "height:" + contentHeight + "px"
        );
        accordion.scrollIntoView()
      } else {
        let accordion = document.querySelector(id)

        accordion.scrollIntoView()
      }

    },
    up() {
      window.scroll(0, 0)
    }
  }
};
</script>
